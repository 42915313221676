import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`You can download my `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1G9jo8nEcOL4JCjl3bkWF4ohSvGugCkyz/view?usp=sharing"
      }}>{`CV from here`}</a>{` or find me on other platforms: `}<a parentName="p" {...{
        "href": "https://github.com/c-obsesie"
      }}>{`GitHub`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/cosmin-mihalache-731557178/"
      }}>{`Linkedin`}</a>{`.`}</p>
    <h3>{`Email`}</h3>
    <h1></h1>
    <h5><a parentName="h5" {...{
        "href": "mailto:cosmin.mihalache95@gmail.com"
      }}>{`cosmin.mihalache95@gmail.com`}</a></h5>
    <h1></h1>
    <h1></h1>
    <h2></h2>
    <h4>{`Phone number`}</h4>
    <h1></h1>
    <h5>{`RO: +40 (0) 756 418 031`}</h5>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      