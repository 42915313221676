import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1></h1>
    <h1></h1>
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Cosmin is a Front-End Developer with experience in React, React-Native, HTML5, CSS3, JavaScript, SASS/SCSS, WordPress and E-Comerce platforms.
Also familiar with UI/UX design tools such as Figma.`}</p>
    </blockquote>
    <p>{`Graduate with honors from BSc in Psychology (Birmingham City University) and MSc degree in Sport Psychology (University of Portsmouth).`}</p>
    <h1></h1>
    <h1></h1>
    <h1></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      