import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1></h1>
    <h1></h1>
    <h2>{`Projects`}</h2>
    <ProjectCard title="Meditation Mobile App" link="https://github.com/c-obsesie/reflectrn" bg="linear-gradient(to right, #aac7f5 0%, #80abf1 100%)" mdxType="ProjectCard">
   Developed using React Native. Components: LogIn, Register, Audio Player, React Navigation. This App is still under development. More on GitHub.
    </ProjectCard>
    <ProjectCard title="Robodex" link="https://github.com/c-obsesie/robot-dex" bg="linear-gradient(to right, #85b0f7 0%, #3978df 100%)" mdxType="ProjectCard">
  React project to practice skills such as fetching data from API, and functional searchbar component. More on GitHub.
    </ProjectCard>
    <ProjectCard title="Spotify copy in React" link="https://github.com/c-obsesie/spotify-copy" bg="linear-gradient(to right, #4c86e4 0%, #4e4ec4 100%)" mdxType="ProjectCard">
 Spotify copy on React using SASS.
    </ProjectCard>
    <ProjectCard title="Cinema Seat App" link="https://github.com/c-obsesie/Cinema-Seat-App" bg="linear-gradient(to right, #4e4ec4 0%, #282897 100%)" mdxType="ProjectCard">
 Cinema seat app using HTML, CSS and JS.
    </ProjectCard>
    <h1></h1>
    <h1></h1>
    <h1></h1>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      